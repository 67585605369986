// Destinations https://www.weddingwire.com/destination-wedding
// setTimeout(() => { debugger; }, 5000)

import React from 'react';
import { Link } from 'react-router-dom';
import NextLink from 'next/link';
import useSWR from 'swr'; 
import includedGMapsTypes from 'constants/includedGMapsTypes';
import excludedGMapsTypes from 'constants/excludedGMapsTypes';
const slug = require('slug');  

// FIX https://github.com/vercel/next.js/issues/7755#issuecomment-508633125 

import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid'; 
import Typography from '@material-ui/core/Typography';
import MuiLink from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import ButtonLink from 'components/ButtonLink'; 
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Autocomplete from "@material-ui/core/Autocomplete";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import SearchIcon from '@material-ui/icons/Search';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';  
import InputAdornment from '@material-ui/core/InputAdornment'; 
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import SimpleBackdrop from 'widgets/SimpleBackdrop';  

// import ListItemLink from 'components/ListItemLink';  

const useStyles = makeStyles((theme) => ({
	backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  card: {
    width: '100%',
    borderRadius: typeof process.env["NEXT_PUBLIC_HOSTNAME"] !== 'undefined' && process.env["NEXT_PUBLIC_HOSTNAME"] !== 'elopifi.com'
    	? 24
    	: 0,
    boxShadow: '0 1px 3px rgba(60,63,66,.32), 0 4px 12px rgba(60,63,66,.15)',
    overflow: 'hidden',
    maxWidth: typeof process.env["NEXT_PUBLIC_HOSTNAME"] !== 'undefined' && process.env["NEXT_PUBLIC_HOSTNAME"] !== 'elopifi.com'
    	? '354px'
    	: "100%",
    margin: '0 auto' 
  },
  searchWrap: { 
    padding: '0 !important'
  },
  searchInput: {
    margin: '21px 24px 20px',
    padding: 0,
  	border: 'none', 
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent"
    },  	
    "& .MuiInputBase-root": {
      padding: '0 !important'
    },    
    "& .MuiOutlinedInput-input": {
      padding: '0 !important'
    },    
    "& .MuiSvgIcon-root": {
      marginRight: 12
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent"
    },    
    "& .MuiInputAdornment-positionStart": {
      marginRight: "0"
    },    
    "& .MuiAutocomplete-endAdornment": {
      display: "none"
    },    
    "& .MuiInputBase-input": { 
	    fontSize: 16,
	    fontWeight: 'bold',
	    letterSpacing: '.1px', 
    	color: '#202124'
    }
  },
  input: {
		"&::placeholder": {
      textOverflow: "ellipsis !important", 
      fontSize: 16,
      lineHeight: 18,
      opacity: .75,
      fontWeight: 500,
	    letterSpacing: '.2px', 
    	color: '#202124'
    }
  },
  searchInputLabel: {
    position: 'absolute',
    left: '-1000px'
  }
}));   

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };   
let gMapsSessionToken = null;   
const gMapsKeyAntonGavrilenkov = 'AIzaSyB8rjX8IJXuLOSTmSWeQy_IlAedMPyH6Yc';
const gMapsKeyZoomWedding = 'AIzaSyBzpkDYolf8b0fVldEfsLYauEQVV-01P-Y';
const gMapsKeyTonyBaks = 'AIzaSyDzPDw6CmT602HmZgULDpc2cPqGCemRwA0';
const gMapsKeyElopifyApi = 'AIzaSyAMpKz8ZjxaLJ_CDo0LNKaoUVumLpYrBd8';
const gMapsKeyElopify = 'AIzaSyCSCv7sFI8zYzjK7TW-FesL-EkArgdOUWM';
const gMapsKeyCoreApi = 'AIzaSyB3SAuBA9gf_ypuhLObtIv07ERJP6k8L5k';
let gMapsKeys = [gMapsKeyCoreApi];
let gMapsApiKey = gMapsKeys[Math.floor(Math.random()*gMapsKeys.length)];   
let placeSelected = false;   

async function openPlaceDetailsPage(params) { 
	if (typeof params !== 'undefined') {
		// Types
		// State: administrative_area_level_1
		// New York County: administrative_area_level_2
		// Country: country
		// New York: locality
		// Brooklyn sublocality, sublocality_level_1  
		// Hong Kong - google detects as a country
		// Doesn't work but show on google website Pulaski Square, Savannah, GA
		// Doesn't exist Fulton Market Loft
		// Elope on a Ferry - figure out how to add this kind of venues
		// Private meadow with Mount Hood views - figure it out
		// Carmel redwoods cabin - figure it out
		// Bow Bridge Idaho - figure it out
		// Mariposa Meadow at Arizona Nordic Village - Arizona Nordic Village exists but needs detalization "Mariposa Meadow at"
		console.log('openPlaceDetailsPage ', params)

		let type = 'city'; 
		let countryISO3 = '';
		let stateCode = '';
		let slugParts = '';

		// if (params.types.includes('country')) {
		// 	console.log('1')
		// 	type = 'country';
		// 	slugParts = [...params.terms].join('');
		// } else if (params.types.includes('administrative_area_level_1')) {
		// 	console.log('2')
		// 	type = 'state';
		// 	countryISO3 = params.structured_formatting.secondary_text;
		// 	slugParts += ' ' + countryISO3;
		// } else if (params.types.includes('sublocality_level_1')) { 
		// 	if (params.terms.length === 3) {
		// 		// Example: Bronx
		// 		slugParts += [params.terms[0].value, params.terms[1].value, params.terms[2].value].join(' ');
		// 	} else if (params.terms.length === 4) {
		// 		// Example: Manhattan
		// 		slugParts += [params.terms[0].value, params.terms[2].value, params.terms[3].value].join(' ');
		// 	}
		// } else {
		// 	if (params.terms.length === 3) { 
		// 		slugParts += [params.terms[0].value, params.terms[1].value, params.terms[2].value].join(' ');
		// 	} else if (params.terms.length === 2) {
		// 		// Example: Saint Petersburg, Russia
		// 		slugParts += [params.terms[0].value, params.terms[1].value].join(' ');
		// 	} 
		// }

		// For test
		// China
		// "Beijing, Yuexiu District, Guangzhou, Guangdong Province, China"

		// let query = API_HOST + '/places?name_contains=' + params.terms[0].value 
		// 	+ '&type_eq=' + type;

		// if (type === 'state') {
		// 	query += '&'
		// }

		// console.log('query ', query)
		// const placeRecord = await fetch(query); 
		// const placeRecordJson = await placeRecord.json();

		// console.log('placeRecordJson ', placeRecordJson)

		// console.log('slugParts ', slugParts)

		// let placeSlug = slug(slugParts);

		// console.log('placeSlug ', placeSlug)

		console.log('params ', params)

		window.location = '/' + params.slug; 
    // alert('ok') 

		// const homePlacesResponse = await fetch(API_HOST + '/places?type_eq=' 
		// 	+ type + '&name_eq=' 
		// 	+ params.structured_formatting.main_text);

	 //  const homePlacesJson = await homePlacesResponse.json();
		// const { data, error } = useSWR(process.env.API_HOST + '/places?showOnHomePage_eq=true&_sort=order:ASC', fetch) 
	  //  if (error) return <div>failed to load</div>
	  //  if (!data) return <div>loading...</div>
	  // console.log('homePlacesJson ', homePlacesJson)
	}
}  

export default function PlaceSearchBar({action = null, redirect = true, successMessage = null}) { 
	const classes = useStyles(); 
	
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState(''); 
  const [backdropValue, setbackdropValue] = React.useState(false); 
  const [ip, setIp] = React.useState(null); 

  // const gMapsSessionToken = gmaps.util.placesAutoCompleteSessionToken();  
  // console.log('gMapsSessionToken ', gMapsSessionToken)
  // const [gMapsSession, setGMapsSession] = React.useState(gMapsSessionToken);

  const defaultOptions = [
	  {
	    description: 'Colorado, USA',
	    structured_formatting: {
	    	main_text: 'Colorado',
	    	secondary_text: 'USA'
	    }
	  },
	  {
	    description: 'Hawaii, USA',
	    structured_formatting: {
	    	main_text: 'Hawaii',
	    	secondary_text: 'USA'
	    }
	  },
	  {
	    description: 'Las Vegas, NV, USA',
	    structured_formatting: {
	    	main_text: 'Las Vegas',
	    	secondary_text: 'NV, USA'
	    }
	  },
	  {
	    description: 'Miami, FL, USA',
	    structured_formatting: {
	    	main_text: 'Miami',
	    	secondary_text: 'FL, USA'
	    }
	  },
	  {
	    description: 'New York, NY, USA',
	    structured_formatting: {
	    	main_text: 'New York',
	    	secondary_text: 'NY, USA'
	    }
	  },
	  {
	    description: 'New Orleans, LA, USA',
	    structured_formatting: {
	    	main_text: 'New Orleans',
	    	secondary_text: 'LA, USA'
	    }
	  }
	]; 

  const [options, setOptions] = React.useState(defaultOptions);
  const loaded = React.useRef(false); 

  // console.log('options' , options)

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${gMapsApiKey}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      );
    } 
    

    loaded.current = true;
  }

  // const fetchGoogleData2 = (request, callback) => {
  //     console.log('request ', request) 

  //     let sessiontoken = Object.values(request.sessionToken)[0];
	  	
	 //  	const search = fetch(`https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${request.input}&sessiontoken=${sessiontoken}&key=AIzaSyB8rjX8IJXuLOSTmSWeQy_IlAedMPyH6Yc`,
  // 	  	{
  // 	  		mode: "no-cors"
  // 	  	}
  // 		)
	 //  		.then((response) => {
  // 				console.log('response ', response)
  // 				if (response.status !== 200) {
		//         console.log('Looks like there was a problem. Status Code: ' + response.status);
		//         return;
	 //        }

	 //        // Examine the text in the response
	 //        response.json().then(function(data) {
	 //        	console.log(data);
	 //        	callback(data); 
	 //        });
  //       })
		//     .catch(function(err) {
		//     	console.log('Fetch Error :-S', err);
		//     });  
  //   };

  const fetchGoogleData = React.useMemo(
    () =>
      throttle((request, callback) => {
        console.log('request ', request)
        // request.types = ['(regions)']; 
        //  
        
        autocompleteService.current.getQueryPredictions(request, callback);
        window.autocompleteService = autocompleteService;
      }, 200),
    [],
  );  

  React.useEffect(() => {

    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();

      if (gMapsSessionToken === null) {
	      gMapsSessionToken = new window.google.maps.places.AutocompleteSessionToken();
	    }
    }

    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : defaultOptions);
      return undefined;
    }   

    // console.log('gMapsSessionToken ', gMapsSessionToken) 
    fetchGoogleData({ input: inputValue, sessionToken: gMapsSessionToken}, async (results) => { 
      if (active) {
        let newOptions = [];
 
        if (value) {
          newOptions = [value];
        } 

        console.log('results ', results)

        if (results) {
        	// Filter Counties
        	let resultsFiltered = results.filter(item => { 
        		// console.log('item. ', item)
        		// console.log('item.types ', item.types)
        		let includedTypes = [];
        		let excludedTypes = [];

        		if (typeof item.types !== 'undefined') {
	        		includedTypes = item.types.filter(value => typeof includedGMapsTypes[value] !== 'undefined');
	        		excludedTypes = item.types.filter(value => typeof excludedGMapsTypes[value] !== 'undefined');
	        	}

            // console.log(excludedTypes.length)
        		// console.log(excludedTypes)
        		return ((excludedTypes.length === 0 || includedTypes.length > 0) && typeof item.place_id !== "undefined"); 
        	})  

        	let newOptionsNewVal = [...newOptions, ...resultsFiltered]; 
          newOptions = newOptionsNewVal;
        }  

        setOptions(newOptions);

	      if (placeSelected) { 
	      	let placeOptions = newOptions[1];
	      	let placeDetailsRequest = {
	      		placeOptions
	      	}

	      	// console.log('autocompleteService.current ', autocompleteService.current)
	      	// const placeDetails = window.google.maps.places.PlaceDetailsRequest() 
	      	//  setbackdropValue(true)
	      	//  
	      	const actionParams = typeof action?.name !== 'undefined' ? `&action=${action.name}` : '';
	      	const actionValue = typeof action?.value !== 'undefined' && action.value !== ''  ? `&actionValue=${action.value}` : '';
	      	// let query = `/api/gmaps/placeDetails?place_id=${placeOptions.place_id}&description=${placeOptions.description}&sessiontoken=${Object.values(gMapsSessionToken)[0]}${actionParams}${actionValue}`;
	      	let queryParams = `place_id=${placeOptions.place_id}&description=${placeOptions.description}&sessiontoken=${Object.values(gMapsSessionToken)[0]}${actionParams}${actionValue}&NEXT_PUBLIC_NODE_ENV=${process.env["NEXT_PUBLIC_NODE_ENV"]}&NEXT_PUBLIC_HOSTNAME=${process.env["NEXT_PUBLIC_HOSTNAME"]}`;
	      	let query = `${process.env["NEXT_PUBLIC_API_HOST"]}/v1/gmaps/place-details?${queryParams}`;
	      	if (window.ip !== 'undefined') {
	      		query += `&ip=${window.ip}`;
	      	}

	      	console.time('placeDetails')
	      	// console.log('query ', query)
	      	const placeDetails = await fetch(query);  
				  const placeDetailsJson = await placeDetails.json(); 
				  console.timeEnd('placeDetails')
				  // console.log('placeDetailsJson ', placeDetailsJson)
				  gMapsSessionToken = new window.google.maps.places.AutocompleteSessionToken();
			  	placeSelected = false;

			  	if (successMessage) {
			  		alert(successMessage)
			  	}
			  	
			  	if (redirect) {
			  		openPlaceDetailsPage(placeDetailsJson);
			    }
			  	// setbackdropValue(false) 
			  }
      } 
    }); 

    return () => {
      active = false;
    };
  }, [value, inputValue, placeSelected, fetch]);

  const optionClickHandler = (event) => {
  	placeSelected = true; 
  }  

  return (
    <React.Fragment>
	  	<Card className={classes.card}>  
  	  	<Backdrop className={classes.backdrop} open={backdropValue}>
          <CircularProgress color="inherit" />
        </Backdrop> 
	      <CardContent className={classes.searchWrap}> 
	        <Autocomplete 
	          id="google-map-search"
	          style={{ width: '100%', display: 'flex' }}
	          getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
	          filterOptions={(x) => x}
	          options={options}
	          autoComplete
	          // open={true}
	          includeInputInList
	          filterSelectedOptions
	          noOptionsText={'No destinations'}  
	          value={value}
	          popupIcon={null}
	          onChange={(event, newValue) => { 
	            setOptions(newValue ? [newValue, ...options] : options);
	            setValue(newValue);
	          }}
	          onInputChange={(event, newInputValue) => {  
	            setInputValue(newInputValue);
	          }}
	          renderInput={(params) => { 
	            params.InputProps.startAdornment = <InputAdornment position="start">
	              	<SearchIcon  className={classes.icon} />
	            	</InputAdornment>; 
	            params.InputProps.classes = {};
	            params.InputProps.classes.input = classes.input;
	            return (
                <React.Fragment>
                  <TextField id="search-input"
                    className={classes.searchInput}
                    {...params}
                    placeholder="Where do you want to elope?"
                    label=""
                    fullWidth />
                  <label className={classes.searchInputLabel} htmlFor="google-map-search"></label>
                </React.Fragment>
                );
              }}
	          renderOption={(props, option) => { 
	          	if (typeof option.structured_formatting !== 'undefined' && 
	          		typeof option.structured_formatting.main_text_matched_substrings !== 'undefined') {
		            const matches = option.structured_formatting.main_text_matched_substrings;
		            const parts = parse(
		              option.structured_formatting.main_text,
		              matches.map((match) => [match.offset, match.offset + match.length]),
		            );

		            return (
		            	<div {...props}>
			              <Grid onClick={optionClickHandler} container alignItems="center">
			                <Grid item>
			                  <LocationOnIcon className={classes.icon} />
			                </Grid>
			                <Grid item xs>
			                  {parts.map((part, index) => (
			                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
			                      {part.text}
			                    </span>
			                  ))}

			                  <Typography variant="body2" color="textSecondary">
			                    {option.structured_formatting.secondary_text}
			                  </Typography>
			                </Grid>
			              </Grid>
		              </div>
		            );
		          } else { 
		          	return (
		          		<div {...props}>
			              <Grid onClick={optionClickHandler} container alignItems="center">
			                <Grid item>
			                  <LocationOnIcon className={classes.icon} />
			                </Grid>
			                <Grid item xs>
			                  <span style={{ fontWeight: 400 }}>
		                      {option.structured_formatting.main_text}
		                    </span> 
			                  <Typography variant="body2" color="textSecondary">
			                    {option.structured_formatting.secondary_text}
			                  </Typography>
			                </Grid>
			              </Grid>
		              </div>
		            );
		          } 
	          }}
	        />   
	      </CardContent> 
	    </Card>
  	</React.Fragment>
  );
} 
